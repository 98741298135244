import React, { useState } from 'react';
import _ from 'lodash';
import QRCode from 'qrcode.react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useResizeDetector } from 'react-resize-detector';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	collapseTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: -theme.spacing(1),
		cursor: 'pointer'
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
});

const  VAISALA_MD30_DPC_URL = "https://dpc.vionice.io/md30/dpc"


const defaultContent = {
	'android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME': 
	'com.vaisala.cv.md30_dpc/.AdminReceiver',
	'android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM': 
	'GDSEboCYq65OQULirG0iC66SpavHwb_KrK_Nk2sHQm8=',
	'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION': 
	VAISALA_MD30_DPC_URL,
	'android.app.extra.PROVISIONING_SKIP_ENCRYPTION': true
};


const  QRCodeGeneration = (props) =>{

	const { noOptions, spacing = 3 } = props;
	const { width, ref } = useResizeDetector();

	const [qrContent, setQrContent] = useState(defaultContent)
	const [frd, setFrd] = useState(false)
	
	const [networkName, setNetworkName] = useState('')
	const [networkPassword, setNetworkPassword] = useState('')

	const [nameError, setNameError] = useState(false)	
	const [showPassword, setShowPassword] = useState(false)	


	const handleNetworkNameChange = (event) => {
		setNetworkName(event.target.value)
	};

	const handleNetworkPasswordChange = (event) => {
		setNetworkPassword(event.target.value)
	};

	const handleGenerate = () => {

		let new_content = defaultContent
		
		console.log("-- networkName", networkName)
		console.log("-- networkPassword", networkPassword)

		if (networkPassword && !networkName) {
			setNameError(true)
			return;
		}

		if (networkName) {
			let networkNameFinal = '',
				channel = '';

			if (networkName.startsWith('alpha@')) {
				channel = 'alpha';
				networkNameFinal = networkName.substring(6);
			} else if (networkName.startsWith('beta@')) {
				channel = 'beta';
				networkNameFinal = networkName.substring(5);
			} else {
				networkNameFinal = networkName;
			}

			if(channel.length > 0){
				new_content['android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION'] =  VAISALA_MD30_DPC_URL + "-" + channel
				new_content['android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE'] =  {channel} 
			}else{
				new_content['android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE'] = undefined
			}

			if (networkNameFinal) {
				new_content['android.app.extra.PROVISIONING_WIFI_SSID'] = networkNameFinal;
			}
		}else{
			new_content['android.app.extra.PROVISIONING_WIFI_SSID'] = undefined
			new_content['android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE'] = 'NONE'
		}

		if (networkPassword.length > 0) {
			new_content['android.app.extra.PROVISIONING_WIFI_PASSWORD'] = networkPassword
			new_content['android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE'] = 'WPA'
		}

		setQrContent(new_content)
		setFrd(!frd)
		setNameError(false)
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const networkNameField = (
			<TextField
				fullWidth
				required
				label="Wifi network name"
				variant="filled"
				error={nameError}
				helperText={nameError && 'Please input network name'}
				value={networkName}
				onChange={handleNetworkNameChange}
			/>
	);

	const networkPasswordField = (
			<TextField
				fullWidth
				label="Wifi network password"
				variant="filled"
				type={showPassword ? 'text' : 'password'}
				value={networkPassword}
				onChange={handleNetworkPasswordChange}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								edge="end"
								aria-label="Toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
							>
								{!showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
	);

	const button = (
			<Button fullWidth onClick={handleGenerate} color="primary" variant="outlined">
				Generate QR code
			</Button>
	);

	let optionsElement = null;
	if (!noOptions) {
			optionsElement = (
				<Grid item xs={12}>
					<Grid container spacing={spacing}>
						<Grid item xs={12}>
							{networkNameField}
						</Grid>
						<Grid item xs={12}>
							{networkPasswordField}
						</Grid>
						<Grid item xs={12}>
							{button}
						</Grid>
					</Grid>
				</Grid>
			);
	}

	console.log("-- qrContent", qrContent)

	return (
			<Grid container spacing={spacing}>
				{optionsElement}
				<Grid item xs={12} ref={ref}>
					{qrContent['android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE']?.channel}
					{width && <QRCode className="qrcode" renderAs="svg" value={JSON.stringify(qrContent)} size={width} />}
				</Grid>
			</Grid>
	);
}

export default withStyles(styles)(QRCodeGeneration);
