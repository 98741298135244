import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import AppLayout from './components/AppLayout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
	return (
		<Provider store={store}>
			<Router>
				<Routes>
					<Route path="/*" element={<AppLayout/>}></Route>
				</Routes>
			</Router>
		</Provider>
	);
}

export default App;