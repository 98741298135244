import React from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setActiveGuidePage } from '../actions/appActions';
import QRCodeGeneration from './QRCodeGeneration';
import { simLockContent, enableMobileDataContent, alwaysOnDisplayContent } from './FAQ';
import { Link as RouterLink } from 'react-router-dom';
import { Constants } from '../utils/constants';

import start_setup from '../images/guide/install/start_setup.png';
import eula from '../images/guide/install/eula.png';
import setup_in_progress from '../images/guide/install/setup_in_progress.png';

import placeholder_img from '../images/placeholder.png';

import application_img from '../images/guide/dpc_screemshot.png'
import login_img from '../images/guide/login.png'
import ready_img from '../images/guide/sensorview_screemshot.png'

import tap_video from '../videos/tap.mp4'
import qr_video from '../videos/qr.mp4'

const spacing = 3;

const useStyles = makeStyles(theme => ({
	titleBar: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#f5f5f5',
		padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	stepperView: {},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	}
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const renderVideo = function(url) {
	return (
		<div className="video-element">
			<video poster={placeholder_img} autoPlay loop muted>
				<source src={url} />
			</video>
		</div>
	);
};

const defaultVideoGrid = function(video, text) {
	return (
		<div className="grid-overflow">
			<Grid container spacing={spacing}>
				<Grid item sm={12} md={8}>
					{video}
				</Grid>
				<Grid item sm={12} md={12}>
					{text}
				</Grid>
			</Grid>
		</div>
	);
};

const steps = [
	{
		id: 'intro',
		title: 'Getting started',
		content: () => {
			const guideLink = (
				<Link href={Constants.appGuideUrl} target="_blank">
					MD30 Support Pages
				</Link>
			);
			return (
				<div className="grid-overflow">
					<Grid container spacing={spacing}>
						<Grid item xs={12}>
							<Typography>
								This setup process installs the MD30 Mobile data collection
								application, optimises device settings, and grants Vaisala
								administrative rights to the device, In short, your Android device
								is turned into a dedicated MD30 Mobile recording unit.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<blockquote>
								<Typography variant="h6" paragraph>
									<b>Don't turn on the device yet</b>
								</Typography>
								<Typography paragraph>
									This installation process bypasses the standard Android setup.
									Follow the instructions below carefully.
								</Typography>
								<Typography paragraph>
									In the few following steps, you are instructed to insert an SD
									card and a SIM card into the device and then turn on the device.
									After you have turned on the device, it directs you to a
									<i>Welcome/Start/Let's go</i> view. When you reach this view,{' '}
									<b>don't do anything</b> before this guide instructs you to do
									something.
								</Typography>
							</blockquote>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								In case you don’t want to dedicate the device for this purpose,
								check the {guideLink} to install MD30 Mobile app directly from Google
								Play Store.
							</Typography>
						</Grid>
					</Grid>
				</div>
			);
		}
	},
	{
		id: 'sim_power',
		title: 'Preliminary steps',
		content: () => {
			return (
				<ol style={{ margin: 0 }}>
					<li>
						<Typography>Insert an SD card and a SIM card into the device.</Typography>
					</li>
					<li>
						<Typography>
							Turn on the device by holding down the power button.
						</Typography>
					</li>
					<li>
						<Typography>Enter the SIM PIN code if requested.</Typography>
					</li>
					<li>
						<Typography>
							Wait for the <i>Welcome/Start/Let's go</i> view to appear.
						</Typography>
					</li>
				</ol>
			);
		}
	},

	{
		id: 'tap',
		title: 'Bypass normal installation process by opening QR code scanner',
		content: () => {
			const video = renderVideo(tap_video);
			const text = (
				<React.Fragment>
					<Typography>
						Tap the screen in an empty spot <b>exactly 6 times</b> to open the QR code
						scanner.
					</Typography>
					<blockquote>
						<Typography paragraph>
							You can access the QR code scanner from this view only. Even though
							other QR code scanning applications exist, they cannot be used in this
							installation process. If you're unable to return to this view, factory
							reset the device (see the <LinkRouter to="/faq">FAQ</LinkRouter> for
							instructions).
						</Typography>
					</blockquote>
				</React.Fragment>
			);
			return defaultVideoGrid(video, text);
		}
	},
	{
		id: 'scan',
		title: 'Scan QR code to begin setup',
		content: mobile => {
			const video = renderVideo(qr_video, {});

			const text = (
				<React.Fragment>
					<Typography paragraph>
						Scan the shown QR code to automatically start installing the device control
						software and the MD30 Mobile mobile application. If you don't have mobile data,
						you are asked to connect to a Wi-Fi network.
					</Typography>
					<Typography variant="h6">Multiple devices to set up?</Typography>
					<Typography paragraph>
						Use the <LinkRouter to="/qr">QR code generator</LinkRouter> to automatically
						set up a Wi-Fi connection by including Wi-Fi credentials in the QR code.
					</Typography>
				</React.Fragment>
			);

			const qr = <QRCodeGeneration noOptions spacing={spacing} />;

			return (
				<Grid container spacing={spacing}>
					<Grid item xs={12} sm={4}>
						{qr}
					</Grid>
					<Grid item xs={12} sm={8}>
						{video}
					</Grid>
					<Grid item xs={12}>
						{text}
					</Grid>
				</Grid>
			);
		}
	},
	{
		id: 'setup',
		title: 'Install control software',
		content: () => {
			return (
				<Grid container spacing={spacing}>
					<Grid item xs={12}>
						<ol style={{ margin: 0 }}>
							<li>
								<Typography>Start the installation process.</Typography>
							</li>
							<li>
								<Typography>Wait for the installation to finish.</Typography>
							</li>
							<li>
								<Typography>
									Accept the Android End User License Agreement. With some device
									models, you may be required to accept a Privacy Policy as well.
								</Typography>
							</li>
						</ol>
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<Typography color="textSecondary" align="center" gutterBottom>
							1/3
						</Typography>


						<img
							alt="1"
							className="screenshot"
							src={start_setup}
						/>
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<Typography color="textSecondary" align="center" gutterBottom>
							2/3
						</Typography>
						<img
							alt="2"
							className="screenshot"
							src={setup_in_progress}
						/>
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<Typography color="textSecondary" align="center" gutterBottom>
							3/3
						</Typography>
						<img alt="3" className="screenshot" src={eula} />
					</Grid>
				</Grid>
			);
		}
	},
	{
		id: 'settings',
		title: 'Configure settings for optimal MD30 Mobile use',
		content: () => {
			return (
				<div className="grid-overflow">
					<Grid container spacing={spacing}>
						<Grid item xs={12} sm={6}>
							<img alt="MD30 Mobile settings" src={application_img} />
						</Grid>
						<Grid item xs={12}>
							<Typography>
								You should now be viewing the landing page of the device shown
								above. Open the settings menu (middle button on left lover part of the image) and complete
								the steps below.
							</Typography>
						</Grid>
						<Grid container spacing={5} item xs={12}>
							<Grid item xs={12}>
								<Typography variant="h6">1. Disable SIM card lock</Typography>
								{simLockContent}
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h6">2. Enable use of mobile data</Typography>
								{enableMobileDataContent}
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h6">
									3. Disable 'Always On Display' (Samsung Galaxy S10e only)
								</Typography>
								{alwaysOnDisplayContent}
							</Grid>
						</Grid>
					</Grid>
				</div>
			);
		}
	},
	{
		id: 'login1',
		title: 'Open MD30 Mobile mobile application',
		content: () => {
			return (
				<div className="grid-overflow">
					<Grid container spacing={spacing}>
						<Grid item xs={12} sm={6}>
							<img alt="MD30 Mobile application" src={application_img} />
						</Grid>
						<Grid item xs={12}>
							<Typography>
								Open the MD30 Mobile mobile application by touching the 'V' icon.
							</Typography>
						</Grid>
					</Grid>
				</div>
			);
		}
	},
	{
		id: 'login2',
		title: 'Log in to MD30 Mobile mobile application',
		content: () => {
			return (
				<div className="grid-overflow">
					<Grid container spacing={spacing}>
						<Grid item xs={12} sm={6}>
							<img alt="Login view" src={login_img} />
						</Grid>
						<Grid item xs={12}>
							<Typography>
								Log in using the credentials included in the{' '}
								<i>Vaisala MD30 Mobile mobile setup</i> email. Use only one account per
								device.
							</Typography>
						</Grid>
					</Grid>
				</div>
			);
		}
	},
	{
		id: 'finished',
		title: 'You are ready to start recording!',
		content: () => {
			const appGuideLink = (
				<Link href={Constants.appGuideUrl} target="_blank">
					here
				</Link>
			);
			const mapLink = (
				<Link href="https://map.vionice.io" target="_blank">
					RoadAI Map
				</Link>
			);

			return (
				<div className="grid-overflow">
					<Grid container spacing={spacing}>
						<Grid item xs={12} md={6}>
							<img alt="MD30 Mobile mobile application" src={ready_img} />
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom>
								The MD30 Mobile mobile application user guide can be found {appGuideLink}.
							</Typography>
							<Typography gutterBottom>
								The recorded data can be viewed in {mapLink}.
							</Typography>
						</Grid>
					</Grid>
				</div>
			);
		}
	}
];

const renderStep = function(item, index, mobile) {
	const { id, content } = item;
	const classes = classNames({
		'stepper-view-container': true,
		'fade-in': true
	});
	return (
		<div key={id} className={classes}>
			{content(mobile)}
		</div>
	);
};

function Instructions(props) {
	const classes = useStyles();

	const { mobile, guidePage: activeStep } = props;

	function handleNext() {
		window.scrollTo(0, 0);
		props.setActiveGuidePage(activeStep + 1);
	}

	function handleBack() {
		window.scrollTo(0, 0);
		props.setActiveGuidePage(activeStep - 1);
	}

	const maxSteps = steps.length;

	let backButton = null;
	if (Boolean(activeStep)) {
		backButton = (
			<Grid item>
				<Button
					variant="contained"
					size="small"
					onClick={handleBack}
					startIcon={<KeyboardArrowLeft />}
				>
					Back
				</Button>
			</Grid>
		);
	}

	let nextButton = null;
	if (activeStep < maxSteps - 1) {
		nextButton = (
			<Grid item>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={handleNext}
					endIcon={<KeyboardArrowRight />}
				>
					Next
				</Button>
			</Grid>
		);
	}

	const item = steps[activeStep];
	const content = renderStep(item, activeStep, mobile);

	return (
		<Grid container spacing={spacing} className="instructions-container">
			<Grid item xs={12}>
				<div className={classes.titleBar}>
					<Typography style={{ fontWeight: 500 }}>{item.title}</Typography>
					<Typography color="textSecondary" style={{ marginLeft: 12 }}>
						{`${activeStep + 1}/${maxSteps}`}
					</Typography>
				</div>
			</Grid>
			<Grid item xs={12}>
				<div className={classes.stepperView}>{content}</div>
			</Grid>
			<Grid container spacing={1} item xs={12}>
				{backButton}
				{nextButton}
			</Grid>
		</Grid>
	);
}

export default connect(
	state => {
		return {
			mobile: state.appReducer.isMobile,
			guidePage: state.appReducer.guidePage
		};
	},
	{
		setActiveGuidePage
	}
)(Instructions);
