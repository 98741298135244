import React from 'react';
import { useLocation } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { setMobile } from '../actions/appActions';
import App from './App';


const muiTheme = createTheme({
	palette: {
		primary: {
			main: '#009ac8'
		},
		secondary: {
			main: '#f50057'
		}
	},
	overrides: {
		MuiAppBar: {
			root: {
				boxShadow: '0 2px 5px 2px rgba(60, 64, 67, 0.15)'
			}
		},
		MuiTooltip: {
			popper: {
				opacity: 1
			},
			tooltip: {
				fontSize: '0.9rem',
				backgroundColor: 'rgb(104, 104, 104)',
				textAlign: 'center'
			}
		},
		MuiCardHeader: {
			title: {
				fontSize: '1.35rem'
			}
		},
		MuiCardContent: {
			root: {
				'&:last-child': {
					paddingBottom: 16
				}
			}
		},
		MuiAvatar: {
			colorDefault: {
				width: 36,
				height: 36,
				backgroundColor: '#009ac8'
			}
		}
	}
});

function AppLayout(props) {
	
	let location = useLocation();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	
	React.useEffect(() => {
		props.setMobile(mobile);
	},[mobile]);

	return (
		<MuiThemeProvider theme={muiTheme}>
			<App mobile={mobile} location={location} />
		</MuiThemeProvider>
	);
}

export default connect(
	state => {
		return {};
	},
	{
		setMobile
	}
)(AppLayout);
