import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Instructions from './Instructions';
import QRCodeGeneration from './QRCodeGeneration';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { FAQ } from './FAQ';
import Guides from './Guides';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Routes, Route, Link as RouteLink } from 'react-router-dom';

import vaisala_logo from '../images/vaisala.svg'

const spacing = 3;

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(6)
	},
	tabContent: {
		padding: theme.spacing(3)
	},
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		marginBottom: theme.spacing(4)
	},
	tabs: {
		flexShrink: 0,
		borderRight: `1px solid ${theme.palette.divider}`,
		marginRight: theme.spacing(3)
	}
}));

const VerticalTab = withStyles(theme => ({
	root: {
		opacity: 0.5,
	}
}))(props => <Tab {...props} />);

const appBar = (
	<AppBar className="app-bar" position="sticky">
		<Toolbar>
			<img alt="Vaisala" className="logo" src={vaisala_logo} />
			<Typography variant="h6" color="inherit" noWrap>
				MD30 Mobile Dedicated Setup
			</Typography>
		</Toolbar>
	</AppBar>
);

const footer = (
	<div className="footer">
		<Typography className="footer-text" variant="caption" color="textSecondary">
			Contact us: <Link href="mailto:helpdesk@vaisala.com">helpdesk@vaisala.com</Link>
		</Typography>
	</div>
);

const tabsConfig = [
	{
		id: 'setup',
		to: '/',
		title: 'Setup',
		content: <Instructions />
	},
	{
		id: 'qr',
		to: '/qr',
		title: 'QR code',
		content: (
			<Grid container spacing={spacing}>
				<Grid item xs={12} sm={6} md={4}>
					<QRCodeGeneration />
				</Grid>
			</Grid>
		)
	},
	{
		id: 'faq',
		to: '/faq',
		title: 'FAQ',
		content: <FAQ />
	},
	{
		id: 'guides',
		to: '/guides',
		title: 'Guides',
		content: <Guides />
	}
];

const renderTabContent = function(item) {
	return (
		<Route 
			key={item.id}
			path={item.to} 
			element={
				<div role="tabpanel">
					<div>{item.content}</div>
					{footer}
				</div>
			}
		/>
	);
};

const tabsV = tabsConfig.map((item, index) => {
	return (
		<VerticalTab
			key={item.id}
			value={item.to}
			label={item.title}
			component={RouteLink}
			to={item.to}
		/>
	);
});

const tabsH = tabsConfig.map((item, index) => {
	return (
		<Tab
			key={item.id}
			value={item.to}
			label={item.title}
			component={RouteLink}
			to={item.to}
		/>
	);
});

function desktopContent(location, classes) {
	const tabElements = tabsConfig
		.map((item, index) => renderTabContent(item, location.pathname))
		.reverse();
		
	return (
		<Container className={classes.container}>
			<div className={classes.root}>
				<Tabs
					value={location.pathname}
					orientation="vertical"
					variant="scrollable"
					className={classes.tabs}
				>
					{tabsV}
				</Tabs>
				<div style={{ flex: 1 }}>
					<Routes>{tabElements}</Routes>
				</div>
			</div>
		</Container>
	);
}

function mobileContent(location, classes) {
	const tabContent = renderTabContent(
		tabsConfig.find(item => item.to === location.pathname),
		location.pathname
	);

	return (
		<React.Fragment>
			<Tabs
				value={location.pathname}
				variant="fullWidth"
				indicatorColor="primary"
				textColor="primary"
				style={{ backgroundColor: '#f5f5f5' }}
			>
				{tabsH}
			</Tabs>
			<div className={classes.tabContent}>{tabContent}</div>
		</React.Fragment>
	);
}

function App(props) {
	const classes = useStyles();
	const { location, mobile } = props;

	if (mobile === null) {
		return null;
	}

	const contentProvider = mobile ? mobileContent : desktopContent;
	const content = contentProvider(location, classes);

	return (
		<React.Fragment>
			{appBar}
			{content}
		</React.Fragment>
	);
}

export default connect(
	state => {
		return {
			mobile: state.appReducer.isMobile
		};
	},
	{}
)(App);
