import React from 'react';
import Link from '@material-ui/core/Link';
import { Constants } from '../utils/constants';

export default function Guides(props) {
	const linkConfig = [
		{
			_id: 'main_page',
			text: 'MD30 Support Pages',
			url: Constants.mainPage
		},{
			_id: 'app',
			text: 'MD30 User guides',
			url: Constants.appGuideUrl
		}
	];

	const links = linkConfig.map(link => (
		<Link
			key={link._id}
			href={link.url}
			target="_blank"
			rel="noopener"
			display="block"
			gutterBottom
		>
			{link.text}
		</Link>
	));

	return <React.Fragment>{links}</React.Fragment>;
}
